<template>
  <footer class="footer-area footer-default bg_color--5">
    <div class="footer-wrapper">
      <v-row align="end" class="row--0">
        <v-col md="7" sm="12" cols="12">
          <div class="footer-left">
            <div class="d-flex">
              <!-- <div style="height:100%; width: 100%"> -->
              <v-row style="height:100% !important; width: 100% !important;">
                <v-col cols="5" lg="5" md="12" sm="12" class="d-flex flex-column inner mx-5">
                  <div class="d-flex">
                    <v-img src="../../assets/images/logo/SIDlogo2.png" class="styling-logo-footer" />
                  </div>
                  <span>
                    Equity Tower, 35th Floor. Unit D, G, H.<br>
                    Jl. Jend. Sudirman Kav. 52-53 (SCBD).<br>
                    Kebayoran Baru, Jakarta 12190,
                    Indonesia.
                  </span>

                  <div class="footer-widget mt-3">
                    <h6>Follow us</h6>

                    <div class="social-share-inner">
                      <ul class="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                        <li v-for="(social, i) in socialList" :key="i">
                          <a :href="social.url" target="_blank">
                            <i class="fab" :class="social.icon"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-col>
                <v-col cols="5" lg="5" md="12" sm="12" class="d-flex flex-column inner mx-5">
                  <h6>Enable “Enterprises Digital and Culture Transformation” with Data. STARTS WITH
                    ENGINEERING FIRST.</h6>
                  <div class="mt-5" style="display: flex;">
                    <!-- <v-img src="../../assets/images/logo/ISO 9001 cert mark.png" width="50px" height="100%" /> -->
                    <!-- <v-img src="../../assets/images/logo/ISO 27001 cert mark v2.png" width="50px" height="100%" /> -->
                    <!-- <v-img src="../../assets/images/logo/ISO NO.png" width="auto"/> -->
                    <v-img src="../../assets/images/logo/iso_kan_group - 2024.png" style="opacity: 75%;" class="styling-logo-footer" width="auto"/>
                  </div>
                </v-col>
              </v-row>
              <!-- <router-link class="rn-button-style--2" to="/contact"
                  ><span>Contact Us</span></router-link
                > -->
              <!-- </div> -->
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col md="5" sm="12" cols="12">
          <div class="footer-right" data-black-overlay="6">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="3" sm="12" cols="12">
                <div class="footer-widget">
                  <!-- <h4>Menu</h4> -->
                  <h4>Solutions</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList" :key="i">
                      <router-link :to="nav.to">{{ nav.navItem }}</router-link>
                      <!-- <span style="color: gray;">{{ nav.navItem }}</span> -->
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
              <v-col lg="3" sm="12" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <!-- <h4>Discover</h4> -->
                  <h4>About Us</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList2" :key="i">
                      <router-link :to="nav.to">{{ nav.navItem }}</router-link>
                      <!-- <span style="color: gray;">{{ nav.navItem }}</span> -->
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col lg="3" sm="12" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>Insight</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList3" :key="i">
                      <router-link :to="nav.to">{{ nav.navItem }}</router-link>
                      <!-- <span style="color: gray;">{{ nav.navItem }}</span> -->
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col lg="3" sm="12" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>Industries</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList4" :key="i">
                      <router-link :to="nav.to">{{ nav.navItem }}</router-link>
                      <!-- <span style="color: gray;">{{ nav.navItem }}</span> -->
                    </li>
                  </ul>
                </div>
              </v-col>

              <v-col lg="12">
                <div class="copyright-text">
                  <p>
                    Copyright © {{ new Date().getFullYear() }} Sibernetik Integra Data. All rights reseved
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "https://www.facebook.com/",
        },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/",
        },
        {
          icon: "fa-twitter",
          url: "https://twitter.com/",
        },
        {
          icon: "fa-instagram",
          url: "https://www.instagram.com/",
        },
      ],
      navList: [
        // {
        //   navItem: "Solution",
        //   to: "/portfolio",
        // },
        // {
        //   navItem: "Service",
        //   to: "/about",
        // },
        // {
        //   navItem: `Insight`,
        //   to: "/contact",
        // },
        // {
        //   navItem: `Use Cases`,
        //   to: "/contact",
        // },
        {
          navItem: "Data Engineering",
          to: "/solutions/data",
        },
        {
          navItem: "Application Engineering",
          to: "/solutions/application",
        },
        {
          navItem: "Security Engineering",
          to: "/solutions/security",
        },
      ],
      navList2: [
        // {
        //   navItem: "About Us",
        //   to: "/portfolio",
        // },
        {
          navItem: "Article",
          to: "/article",
        },
        {
          navItem: "Career",
          to: "/siber-career",
        },
        {
          navItem: `Contact Us`,
          to: "/contact-us",
        },
      ],
      navList3: [
        {
          navItem: "Perspective",
          to: "/perspectives",
        },
        {
          navItem: "Articles",
          to: "/article",
        },
      ],
      navList4: [
        {
          navItem: "Banking",
          to: "/industries/banking-financial-services",
        },
        {
          navItem: "Telecommunication",
          to: "/industries/telecommunications",
        },
        {
          navItem: "FMCG",
          to: "/industries/fast-moving-consumer-goods",
        },
        {
          navItem: "Government",
          to: "/industries/governments-agencies-military-defense-police",
        },
      ],
    };
  },
};
</script>
<style>
@media only screen and (min-width: 768px) and (max-width: 1920px) {
  .styling-logo-footer {
    width: 100% !important;
  }
}
</style>
